// @flow
import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import tree from './tree'
import lang from './lang'
import urlHack from './url'

export default function createRootReducer(history: History) {
  return combineReducers({
    router: connectRouter(history),
    tree,
    lang,
    urlHack,
  })
}
