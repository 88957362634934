/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/destructuring-assignment */

// @flow

import { SortableTreeWithoutDndContext as SortableTree, addNodeUnderParent, removeNodeAtPath, changeNodeAtPath, getNodeAtPath } from 'react-sortable-tree'
import React, { useState } from 'react'
import Select from 'react-select'
import Beforeunload from 'react-beforeunload'
import type { Tree, TreeNode, EntryFAQ, Language, Event } from '../types'
import { lockedDown, genRandomKey } from '../utils/keys'
import styles from './Admin.module.css'
import AdminSlideList from './AdminSlideList'
import MarkdownRTF from './MarkdownRTF'
import ICalendar from './ICalendar'
import axios from 'axios'
import config from '../config.json'

const getNodeKey = ({ node }) => node.id

const prettyMap = {
  'submenu': 'Menü',
  'video': 'Video',
  'gallery': 'Gallery',
  'website': 'Website',
  'faq': 'FAQ',
  'calendar': 'Calendar',
}
const internalPrettyMap = {
  'internal#screensaver': 'Screensaver',
  'internal#aon-button': 'Always Visible Button',
  'internal#banner': 'Main Banner',
}

const prettyMapTitle = {
  'img': 'Image/Picture',
  'video': 'Video',
  'html': 'HTML',
  'url': 'URL',
}

const badgeColor = {
  'submenu': '#4A90E2',
  'video': '#F5A623',
  'gallery': '#7ED321',
  'website': '#13635a',
  'faq': '#c10d10',
  'calendar': '#edd60b',
  'internal#screensaver': '#d9d9d9',
  'internal#aon-button': '#e9d9e9',
  'internal#banner': '#f9d9d9',
}

const colors = ['purple', 'red', 'blue', 'green']

const pretty = (s: string) => {
  const m = { ...prettyMap, ...internalPrettyMap }
  if (m[s]) return m[s]
  return s
}

type Props = {
  tree: Tree,
  fetchTree: () => void,
  setTree: Tree => mixed
}

type State = {
  selected?: {
    path: string[],
    node: TreeNode
  },
  calendarKey: number,
  selectedEvent?: number,
  uploading?: boolean | string,
}
const updateSlidesServer = (newSlides) => {
  return axios.post(`${config.apiUrl}slides`, newSlides)
}

const FaqLang = ({ el, ix, langCode, updateQuestionItem }: { el: EntryFAQ, ix: number, langCode: Language, updateQuestionItem: any }) => (
  <React.Fragment>
    <div className={styles.settingsEntry}>
      <div className={styles.settingsName}>
        Question ({langCode}):
      </div>
      <div className={styles.settingsValue}>
        <input className={styles.input} value={el.question[langCode]} onChange={updateQuestionItem(ix, 'question', langCode)} />
      </div>
      </div>
      <div className={styles.settingsEntry}>
      <div className={styles.settingsName}>
          Answer ({langCode}):
      </div>
      <div className={styles.settingsValue}>
        <MarkdownRTF className={styles.input} markdown={el.answer[langCode]} setMarkdown={updateQuestionItem(ix, 'answer', langCode)} />
      </div>
  </div>
</React.Fragment>
)

const InputItem = ({ info, type, value, onChange, inputStyle, children }: {
  info: string,
  value: any,
  type?: string,
  onChange: any => void,
  inputStyle?: any,
  children?: any,
}) => {
  return (
    <div className={styles.settingsEntry}>
      <div className={styles.settingsName}>
        {info}
      </div>
      <div className={styles.settingsValue}>
        <input type={type || 'text'} className={styles.input} style={inputStyle || {}} value={value} onChange={onChange} />
        {children}
      </div>
    </div>
  )
}

const ResourceEditor = ({ events, resources, setResources, setEvents }) => {
  const [selected, _setSelected] = useState(null)
  const [name, setName] = useState('')

  const setSelected = (resId) => {
    if (resId === null) {
      _setSelected(null)
      setName('')
      return
    }
    const res = resources.find((v) => v.id === resId)
    if (!res) {
      console.warn(`resource not found`)
      return
    }
    _setSelected(resId)
    setName(res.name)
  }

  const addResource = () => {
    if (selected === null) {
      setResources([...resources, {
        id: genRandomKey(),
        name: name,
      }])
      setSelected(null)
    } else {
      setResources(resources.map((v) => (
        (v.id === selected)
          ? { ...v, name: name }
          : v
      )))
    }
  }
  const deleteResource = () => {
    const res = resources.find((v) => v.id === selected)
    if (!res) { return }
    // eslint-disable-next-line no-restricted-globals
    if (confirm(`are you sure you want to delete the resource '${res.name}'?`)) {
      setEvents(events.filter((v) => v.resourceId !== selected))
      setResources(resources.filter((v) => v.id !== selected))
      setSelected(null)
    }
  }
  return (
    <React.Fragment>
      <div className={`${styles.resourceList}`}>
        <button className={styles.resourceCancelItem} onClick={() => setSelected(null)}>
          X
        </button>
        {resources.map((res) => (
          <button className={res.id === selected ? styles.resourceItemSelected : styles.resourceItem} onClick={() => setSelected(res.id)}>
            {res.name}
          </button>
        ))}
      </div>
      <div className={styles.settingsEntry}>
        <div className={styles.settingsName}>
          Resource:
        </div>
        <div className={styles.settingsValue}>
          <input className={styles.input} value={name} onChange={(event) => setName(event.target.value)} />
        </div>
      </div>
      <div className={styles.resourceControls}>
        <button className={styles.resourceButton} onClick={addResource}>
          {selected === null ? 'add' : 'edit'}
        </button>
        {selected && (
          <button className={styles.resourceButtonDelete} onClick={deleteResource}>
            delete
          </button>
        )}
      </div>
    </React.Fragment>
  )
}

// as a constraint, to simplify the management of calendar and such the id might be invalid
// (this can be improved by making separate components and modularization of the "slide types")
const EventEditor = ({ events, deleteEvent, changeColor, setEvents, selected, setSelected }) => {
  
  const [timer, setTimer] = useState(null)
  const event = events.find((ev) => ev.id === selected)
  const [title, _setTitle] = useState(event ? event.title : '')
  if (!event) {
    return null
  }
  const setTitle = (title) => {
    if (timer) {
      clearTimeout(timer)
    }
    _setTitle(title)
    setTimer(setTimeout(() => {
      setEvents(events.map((ev) => 
        ev.id === selected
          ? { ...ev, title }
          : ev
      ))
    }, 200))
  }
  return (
    <React.Fragment>
      <InputItem info={'Event Title'} value={title} inputStyle={{ width: 'calc(100% - 180px - 24px)', }} onChange={(event) => setTitle(event.target.value)}>
        <div>
          <div className={`${styles.resourceButtonDelete} ${styles.eventUnselect}`} style={{
            backgroundColor: event.bgColor,
          }} onClick={() => changeColor(event)}>
            Change Color
          </div>
          <div className={`${styles.resourceButtonDelete} ${styles.eventUnselect}`} onClick={() => deleteEvent(event)}>
            Delete
          </div>
        </div>
      </InputItem>
      <div className={`${styles.resourceButton} ${styles.eventUnselect}`} onClick={() => setSelected(null)}>
          Unselect
        </div>
      <div className={styles.dividerStandalone} />
    </React.Fragment>
  )
}

export default class Admin extends React.Component<Props, State> {

  dirty: boolean
  _cancel: null | () => mixed

  constructor (props: Props) {
    super(props)
    this.state = {
      calendarKey: 0,
    }
    this._cancel = null
    this.dirty = false
  }

  setTree = (tree: Tree) => {
    this.dirty = true
    this.props.setTree(tree)
  }

  upload = () => {
    updateSlidesServer(this.props.tree)
      .then(() => {
        this.dirty = false
        alert(`uploaded`)
      })
      .catch(() => alert(`error uploading :(), try again?`))
  }

  componentDidMount () {
    this.dirty = false
  }

  uploadFile = (file: any) => {
    if (this._cancel) {
      this._cancel()
      this._cancel = null
    }
    this.setState({ uploading: true })
    const formData = new FormData()
    formData.append('file', file)
    return axios({
      method: 'post',
      url: `${config.apiUrl}upload`,
      data: formData,
      config: {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      },
      cancelToken: new axios.CancelToken((c) => {
        this._cancel = c
      }),
    })
    .then((ret) => {
      this.setState({ uploading: false })
      return `${config.apiUrl}${ret.data}`
    })
  }

  addNew = () => {
    const newNode = this.newNode()
    this.setTree([
      ...this.props.tree,
      newNode,
    ])

    this.setState({ selected: {
      path: [newNode.id],
      node: newNode
    }})
  }

  reload = () => {
    this.props.fetchTree()
  }

  export = () => {
    console.log(JSON.stringify(this.props.tree))
  }

  onChange = (treeData: Tree) => {
    this.setTree(treeData)
  }

  newNode = () => ({
    id: genRandomKey(),
    title: "New Slide",
    type: 'website',
    titleType: 'img',
    titleContent: '',
    list: [],
  })

  resetSelection = () => {
    this.setState({ selected: undefined })
  }

  uploadingErrHandler = (err: any) => {
    if (axios.isCancel(err)) {
      this.setState({ uploading: false })
    } else {
      this.setState({ uploading: err.message })
    }
  }

  renderSpecific = ({ node, path }: { node: TreeNode, path: string[] }) => {
    const videoRef = React.createRef()
    const imageRef = React.createRef()
    const buttonRef = React.createRef()

    const update = (key) => (event) => {
      let val = null
      if (event && event.target && typeof event.target.value === 'string') {
        val = event.target.value
      } else {
        val = event
      }
      this.setTree(changeNodeAtPath({
          treeData: this.props.tree,
          path,
          getNodeKey,
          newNode: { ...node, [key]: val },
      }))
    }

    const updateSrc = update('src')
    const updateListItem = (ix, typ) => (event) => {
      const val = event.target.value
      // $FlowFixMe
      const newList = [...node.list]
      newList[ix] = { ...newList[ix] }
      newList[ix][typ] = val
      this.setTree(changeNodeAtPath({
        treeData: this.props.tree,
        path,
        getNodeKey,
        newNode: { ...node, list: newList },
     }))
    }
    const updateList = update('list')
    const deleteWeb = (ix1) => {
      // $FlowFixMe
      updateList(node.list.filter((v, ix2) => ix1 !== ix2))
    }
    const newWeb = () => {
      updateList([
        // $FlowFixMe
        ...node.list,
        {
          title: 'new web',
          url: 'https://new.web/'
        }
      ])
    }
    const updateQuestionItem = (ix, typ, lang) => (event) => {
      const val = typeof event === 'string' ? event : event.target.value
      // $FlowFixMe
      const newQuestions = [...node.questions]
      newQuestions[ix] = { ...newQuestions[ix] }
      newQuestions[ix][typ] = { ...newQuestions[ix][typ] }
      newQuestions[ix][typ][lang] = val
      this.setTree(changeNodeAtPath({
        treeData: this.props.tree,
        path,
        getNodeKey,
        newNode: { ...node, questions: newQuestions },
     }))
    }

    const updateGoHome = (ev: any) => {
      const val = ev.target.checked
      this.setTree(changeNodeAtPath({
        treeData: this.props.tree,
        path,
        getNodeKey,
        newNode: { ...node, goHome: val },
     }))
    }

    const updateQuestions = update('questions')
    const deleteQuestion = (ix1) => {
      // $FlowFixMe
      updateQuestions(node.questions.filter((v, ix2) => ix1 !== ix2))
    }
    const newQuestion = () => {
      updateQuestions([
        // $FlowFixMe
        ...node.questions,
        {
          question: {
            'de': 'german question',
            'en': 'english question',
          },
          answer: {
            'de': 'german answer',
            'en': 'english answer',
          }
        }
      ])
    }
    const updateContent = (content) => {
      this.setTree(changeNodeAtPath({
          treeData: this.props.tree,
          path,
          getNodeKey,
          // $FlowFixMe
          newNode: { ...node, content: typeof content === 'function' ? content(node.content) : content },
      }))
    }
    const onSelectVideo = () => {
      ((async () => {
        try {
          const file = videoRef.current && videoRef.current.files && videoRef.current.files[0]
          if (!file) return
          const rets = await this.uploadFile(file)
          return updateSrc(rets)
        } catch (err) {
          console.error(err)
        }
      })()).catch(this.uploadingErrHandler) 
    }

    const onSelectImage = () => {
      ((async () => {
        try {
          const file = imageRef.current && imageRef.current.files && imageRef.current.files[0]
          if (!file) return
          const rets = await this.uploadFile(file)
          return updateContent((content) => [...content, rets])
        } catch (err) {
          console.error(err)
        }
      })()).catch(this.uploadingErrHandler) 
    }

    const onSelectAonButton = () => {
      ((async () => {
        try {
          const file = buttonRef.current && buttonRef.current.files && buttonRef.current.files[0]
          if (!file) return
          const rets = await this.uploadFile(file)
          return updateSrc(rets)
        } catch (err) {
          console.error(err)
        }
      })()).catch(this.uploadingErrHandler) 
    }

    const setEvents = (evs) => {
      this.setTree(changeNodeAtPath({
        treeData: this.props.tree,
        path,
        getNodeKey,
        newNode: { ...node, events: evs },
      }))
      this.setState((state) => ({ calendarKey: state.calendarKey + 1 }))
    }
    const addEvent = (ev, helpers) => {
      // $FlowFixMe
      setEvents(helpers.addEvent(node.events, ev))
      this.setState((state) => ({ calendarKey: state.calendarKey + 1 }))
    }

    const editEvent = (ev: Event) => {
      this.setState({ selectedEvent: ev.id })
    }

    const deleteEvent = (ev: Event) => {
      // eslint-disable-next-line no-restricted-globals
      if (confirm(`Are you sure you want to delete '${ev.title}'?`)) {
        // $FlowFixMe
        setEvents(node.events.filter((e) => e.id !== ev.id))
      }
    }

    const changeColor = (ev: Event) => {
      const nextColor = (given: string) => {
        const ix = colors.findIndex((s) => s === given)
        if (ix < 0) {
          return colors[0]
        } else {
          if (colors[ix+1]) {
            return colors[ix+1]
          } else {
            return colors[0]
          }
        }
      }
      // $FlowFixMe
      setEvents(node.events.map((e) =>
        e.id === ev.id
          ? { ...e, bgColor: nextColor((e: any).bgColor) }
          : e
        ))
    }

    const setResources = (res) => {
      update('resources')(res)
      this.setState((state) => ({ calendarKey: state.calendarKey + 1 }))
    }

    switch (node.type) {
      case 'video':
          return (
            <React.Fragment>
              <input ref={videoRef} type="file" accept=".mp4,.webm" onChange={onSelectVideo} style={{display: 'none'}}/>
              <div className={styles.settingsEntry}>
                  <div className={styles.settingsName}>
                    Video:
                  </div>
                  <div className={styles.settingsValue}>
                    {node.src ? node.src : 'no file'}
                    <button type="button" className={styles.addImageButton} onClick={() => videoRef.current && videoRef.current.click()}>
                      Video hochladen
                    </button>
                  </div>
              </div>
            </React.Fragment> 
          )
      case 'internal#screensaver':
          return (
            <React.Fragment>
              <input ref={imageRef} type="file"  accept=".jpg,.jpeg,.png,.gif" onChange={onSelectImage} style={{display: 'none'}}/>
              <div className={styles.settingsEntry}>
                {node.id && <AdminSlideList nodeId={node.id} elements={node.content} setImages={updateContent} />}
                <button type="button" className={styles.addImageButton} onClick={() => imageRef.current && imageRef.current.click()}>
                  Bild hochladen
                </button>
              </div>
              <InputItem info={'Anzeigedauer einer Slide'} type='number' value={node.intervalSeconds} onChange={update('intervalSeconds')} />
              <InputItem info={'Zeit der Inaktivität bis zum Screensaver'} type='number' value={node.timeout} onChange={update('timeout')} />
              <div className={styles.settingsEntry}>
                  <div className={styles.settingsName}>
                    Go to Home (instead of showing pictures)
                  </div>
                  <div className={styles.settingsValue}>
                  <input type='checkbox' checked={node.goHome || false} onChange={updateGoHome} />
                  </div>
              </div>
            </React.Fragment>
          )
      case 'gallery':
            return (
              <React.Fragment>
                <input ref={imageRef} type="file"  accept=".jpg,.jpeg,.png,.gif" onChange={onSelectImage} style={{display: 'none'}}/>
                  <div className={styles.settingsEntry}>
                    {node.id &&
                      <AdminSlideList nodeId={node.id} elements={node.content} setImages={updateContent} />}
                    <button type="button" className={styles.addImageButton} onClick={() => imageRef.current && imageRef.current.click()}>
                      Bild hochladen
                    </button>
                    Note: if a single image is specified, it'll be shown in fullscreen
                  </div>
              </React.Fragment>
            )
      case 'submenu':
          return (
            <React.Fragment>
                  <div className={styles.settingsEntry}>
                    Move components on the sidebar
                  </div>
            </React.Fragment>
          )
      case 'website':
        return (
          <React.Fragment>
            <div className={styles.settingsEntry}>
              
              <div className={styles.webList}>
                {node.list.map((el, ix) => (
                  <div key={ix} className={styles.webEntry}>
                    <button key='delete' className={`${styles.webButton} ${styles.webDelete}`} onClick={() => deleteWeb(ix)}>
                      Delete
                    </button>
                    <div key='title' className={styles.settingsEntry}>
                      <div className={styles.settingsName}>
                        Title:
                      </div>
                      <div className={styles.settingsValue}>
                        <input className={styles.input} value={el.title} onChange={updateListItem(ix, 'title')} />
                      </div>
                    </div>
                    <div key='url-german' className={styles.settingsEntry}>
                      <div className={styles.settingsName}>
                        URL (german):
                      </div>
                      <div className={styles.settingsValue}>
                        <textarea className={styles.input} value={el.url} onChange={updateListItem(ix, 'url')} />
                      </div>
                    </div>

                    <div key='url-english' className={styles.settingsEntry}>
                      <div className={styles.settingsName}>
                        URL (english):
                      </div>
                      <div className={styles.settingsValue}>
                        <textarea className={styles.input} value={el.urlEnglish || ''} onChange={updateListItem(ix, 'urlEnglish')} />
                      </div>
                    </div>
                  </div>
                ))}
                <button key='new-web' className={styles.webButton} onClick={newWeb}>
                  Add new web
                </button>
              </div>
              Note: if a single web is specified, it'll be shown in fullscreen
            </div>
          </React.Fragment>
        )
        case 'faq':
          return (
            <React.Fragment>
              <div className={styles.settingsEntry}>
                <div className={styles.webList}>
                  {node.questions.map((el, ix) => (
                    <div key={ix} className={styles.faqEntry}>
                      <button className={`${styles.webButton} ${styles.webDelete}`} onClick={(ev) => deleteQuestion(ix)}>
                        Delete
                      </button>
                      <FaqLang key='de-q' el={el} langCode='de' ix={ix} updateQuestionItem={updateQuestionItem} />
                      <FaqLang key='en-q' el={el} langCode='en' ix={ix} updateQuestionItem={updateQuestionItem} />
                    </div>
                  ))}
                  <button className={styles.webButton} onClick={newQuestion}>
                    Add new question
                  </button>
                </div>
              </div>
            </React.Fragment>
          )
      case 'calendar':
        return (
          <React.Fragment>
            <InputItem key='top-de' info='Top Text (german)' value={node.topText || ''} onChange={update('topText')} />
            <InputItem key='top-en' info='Top Text (english)' value={node.topTextEnglish || ''} onChange={update('topTextEnglish')} />
            <InputItem key='bottom-de' info='Bottom Text (german)' value={node.bottomText || ''} onChange={update('bottomText')} />
            <InputItem key='bottom-en' info='Bottom Text (english)' value={node.bottomTextEnglish || ''} onChange={update('bottomTextEnglish')} />
            <div className={styles.dividerStandalone} />
            <ResourceEditor events={node.events} resources={node.resources} setResources={setResources} setEvents={setEvents} />
            <div className={styles.dividerStandalone} />
            {typeof this.state.selectedEvent === 'number' && (
              <React.Fragment>
                <EventEditor key={this.state.selectedEvent} events={node.events} changeColor={changeColor} deleteEvent={deleteEvent} setEvents={setEvents} selected={this.state.selectedEvent} setSelected={(sel: any) => this.setState({ selectedEvent: sel })}/>
              </React.Fragment>
            )}
            <div className={styles.settingsEntry}>
              <ICalendar track={this.state.calendarKey} width={'40%'} events={node.events} resources={node.resources} readOnly={false} addEvent={addEvent} changeColor={changeColor} setEvents={setEvents} eventClicked={editEvent} deleteClicked={deleteEvent} />
            </div>
          </React.Fragment>
        )
      case 'internal#aon-button':
        return (
          <React.Fragment>
              <input ref={buttonRef} type="file" accept=".png,.jpg,.jpeg" onChange={onSelectAonButton} style={{display: 'none'}}/>
              <InputItem key='src' info='Image' 
                    inputStyle={{ width: 'calc(100% - 180px - 24px)', }} 
                    value={node.src} onChange={update('src')}>
                    <button type="button" className={styles.addImageButton} onClick={() => buttonRef.current && buttonRef.current.click()}>
                      Bild hochladen
                    </button>
              </InputItem>
            <InputItem key='url' info='URL' value={node.url || ''} onChange={update('url')} />
          </React.Fragment>
        )
        case 'internal#banner':
            return (
              <React.Fragment>
                <input ref={imageRef} type="file"  accept=".jpg,.jpeg,.png,.gif" onChange={onSelectImage} style={{display: 'none'}}/>
                  <div className={styles.settingsEntry}>
                    {node.id &&
                      <AdminSlideList nodeId={node.id} elements={node.content} setImages={updateContent} />}
                    <button type="button" className={styles.addImageButton} onClick={() => imageRef.current && imageRef.current.click()}>
                      Bild hochladen
                    </button>
                  </div>
                  <InputItem info={'Anzeigedauer eines Banner Bilds'} type='number' value={node.intervalSeconds} onChange={update('intervalSeconds')} />  
              </React.Fragment>
            )
      default:
        return null
    }
  }

  renderSelected = ({ path }: { path: string[] }) => {

    const options = Object.entries(prettyMap).map(([key,val]) => ({
      value: key,
      label: val,
    }))

    const optionsTitle = Object.entries(prettyMapTitle).map(([key,val]) => ({
      value: key,
      label: val,
    }))

    const find = getNodeAtPath({
      treeData: this.props.tree,
      path,
      getNodeKey,
      ignoreCollapsed: false,
    })
    if (!find ) {
      this.setState({ selected: undefined })
      return null
    }
    const { node } = find
    const update = (key) => (event) => {
      const val = typeof event === 'string' ? event : event.target.value
      this.setTree(changeNodeAtPath({
          treeData: this.props.tree,
          path,
          getNodeKey,
          newNode: { ...node, [key]: val },
      }))
    }

    const updateTyp = (selected: any) => {
      const { value } = selected
      const newType = value
      let newNode = node

      // save current state 
      switch (node.type) {
        case 'submenu':
          newNode = { ...newNode, _children: node.children, children: undefined }
        break
        case 'video':
          newNode = { ...newNode, _video_src: node.src, src: undefined }
        break
        case 'gallery':
          newNode = { ...newNode, _gallery_content: node.content, content: undefined }
        break
        case 'website': 
          newNode = { ...newNode, _website_list: node.list, list: undefined }
        break
        case 'faq':
          newNode = { ...newNode, _faq_qa: node.questions, questions: undefined }
        break
        case 'calendar':
          newNode = { ...newNode, _cal_events: node.events, _cal_resources: node.resources, events: undefined, resources: undefined }
        break
        default:
      }

      // change type
      newNode = { ...newNode, type: newType }

      // recover (if saved data exists) or set default
      switch (newType) {
        case 'submenu':
          newNode = { ...newNode, children: node._children || [] }
        break
        case 'video':
          newNode = { ...newNode, src: node._video_src || undefined, }
        break
        case 'gallery':
          newNode = { ...newNode, content: node._gallery_content || [] }
        break
        case 'website':
          newNode = { ...newNode, list: node._website_list || [] }
        break
        case 'faq':
          newNode = { ...newNode, questions: node._faq_qa || [] }
        break
        case 'calendar':
          newNode = { ...newNode, events: node._cal_events || [], resources: node._cal_resources || [] }
        break
        default:
      }

      this.setTree(changeNodeAtPath({
        treeData: this.props.tree,
        path,
        getNodeKey,
        newNode,
      }))

    }
    
    const updateTitleTyp = (selected: any) => {
      const { value } = selected
      update('titleType')(value)
    }
    const fileRef = React.createRef()
    const onSelectFile = () => {
      ((async () => {
        try {
          const file = fileRef.current && fileRef.current.files && fileRef.current.files[0]
          if (!file) return
          const rets = await this.uploadFile(file)
          return update('titleContent')(rets)
        } catch (err) {
          console.error(err)
        }
      })()).catch(this.uploadingErrHandler) 
    }

    const changeHidden = (ev: any) => {
      const val = ev.target.checked
      this.setTree(changeNodeAtPath({
        treeData: this.props.tree,
        path,
        getNodeKey,
        newNode: { ...node, hidden: val },
     }))
    }
    if (lockedDown(node)) {
      return (
        <React.Fragment>
          <div className={styles.generalSettings}>
            <h2>{node.title}</h2>
            <div className={styles.divider} />
          </div>
          <div className={styles.specificSettings}>
            {this.renderSpecific({node, path})}
          </div>

          <button type='button' className={styles.exitButton} onClick={this.resetSelection}>
            X
          </button>
        </React.Fragment>
      )
    } else {
      return (
        <React.Fragment>
          <div className={styles.generalSettings}>
            <div className={styles.settingsEntry}>
              <div className={styles.settingsName}>
                Name (german):
              </div>
              <div className={styles.settingsValue}>
                <input className={styles.input} value={node.title || ''} onChange={update('title')} />
              </div>
            </div>
            <div className={styles.settingsEntry}>
              <div className={styles.settingsName}>
                Name (english):
              </div>
              <div className={styles.settingsValue}>
                <input className={styles.input} value={node.titleEnglish || ''} onChange={update('titleEnglish')} />
              </div>
            </div>
            <div className={styles.settingsEntry} style={{ paddingLeft: '32px' }}>
              if the english title is not specified, the german one will be used.
            </div>
            <div className={styles.settingsEntry}>
                  <div className={styles.settingsName}>
                    Hidden
                  </div>
                  <div className={styles.settingsValue}>
                  <input type='checkbox' checked={node.hidden || false} onChange={changeHidden} />
                  </div>
              </div>  
            <div className={styles.settingsEntry}>
              <div className={styles.settingsName}>
                Title type:
              </div>
              <div className={styles.settingsValue}>
                <Select options={optionsTitle} value={{ value: node.titleType, label: prettyMapTitle[node.titleType] }} onChange={updateTitleTyp} />
              </div>
            </div>
            {node.titleType === 'img' && <div className={styles.settingsEntry}>
              <div className={styles.settingsName}>
                Title Image:
              </div>
              <div className={styles.settingsValue}>
                <input key='image' ref={fileRef} type="file" accept=".jpg,.jpeg,.png,.gif" onChange={onSelectFile} style={{display: 'none'}}/>
                <input className={styles.input} style={{ width: 'calc(100% - 180px - 24px)', }} value={node.titleContent || ''} onChange={update('titleContent')} />
                <button type="button" className={styles.addImageButton} onClick={() => fileRef.current && fileRef.current.click()}>
                  Bild hochladen
                </button>
              </div>
            </div>}
            {node.titleType === 'video' && <div className={styles.settingsEntry}>
              <div className={styles.settingsName}>
                Title Video:
              </div>
              <div className={styles.settingsValue}>
                  <input key='video' ref={fileRef} type="file" accept=".mp4" onChange={onSelectFile} style={{display: 'none'}}/>
                  <input className={styles.input} style={{ width: 'calc(100% - 180px - 24px)', }} value={node.titleContent || ''} onChange={update('titleContent')} />
                  <button type="button" className={styles.addImageButton} onClick={() => fileRef.current && fileRef.current.click()}>
                    Bild hochladen
                  </button>
              </div>
            </div>}
            {node.titleType === 'html' && <div className={styles.settingsEntry}>
              <div className={styles.settingsName}>
                Title HTML:
              </div>
              <div className={styles.settingsValue}>
                <textarea className={styles.input} value={node.titleContent} onChange={update('titleContent')} />
              </div>
            </div>}
            {node.titleType === 'url' && <div className={styles.settingsEntry}>
              <div className={styles.settingsName}>
                Title URL:
              </div>
              <div className={styles.settingsValue}>
                <textarea className={styles.input} value={node.titleContent} onChange={update('titleContent')} />
              </div>
            </div>}
            <br />
            <div className={styles.settingsEntry}>
              <div className={styles.settingsName}>
                Typ:
              </div>
              <div className={styles.settingsValue}>
                <Select options={options} value={{ value: node.type, label: prettyMap[node.type] }} onChange={updateTyp} />
              </div>
            </div>
            <div className={styles.divider} />
          </div>
          <div className={styles.specificSettings}>
            {this.renderSpecific({node, path})}
          </div>

          <button type='button' className={styles.exitButton} onClick={this.resetSelection}>
            X
          </button>
        </React.Fragment>
      )
    }
  }
  render() {
    const { tree } = this.props
    const { uploading } = this.state
    if (tree.length === 0) {
      this.props.fetchTree()
    }
    const nodeButtons = (node: TreeNode, path: string[]) => {
      let xs = []
      const select = (
        <button
          type="button"
          onClick={() =>
              this.setState({ selected: { node, path }})
            }
        >
          Einstellungen
        </button>
      )
      if (node.type === 'submenu') {
        const button = (
          <button
            type="button"
            onClick={() =>
              {
                const newNode = this.newNode()
                this.setTree(addNodeUnderParent({
                  treeData: tree,
                  parentKey: path[path.length - 1],
                  expandParent: true,
                  getNodeKey,
                  newNode,
                }).treeData)
                this.setState({ selected: {
                  path: [...path, newNode.id],
                  node: newNode
                }})
              }}
          >
            Hinzufügen
          </button>
        )
        xs = [...xs, button]
      }
      
      if (node.id && !lockedDown(node)) {
        const button = (
            <button
              type="button"
              onClick={() =>
                this.setTree(removeNodeAtPath({
                    treeData: tree,
                    path,
                    getNodeKey,
                  }))
              }
            >
            Löschen
          </button>
        )
        xs = [...xs, button]
      }
      
      return [...xs, select]
    }
    return (
        <React.Fragment>
        <Beforeunload onBeforeunload={() => this.dirty ? "You'll lose your data!" : undefined} />
        <div className={`${styles.main} ${uploading ? styles.uploading : ''}`}>
            <div className={styles.tree}>
                <SortableTree
                    treeData={tree}
                    generateNodeProps={({ node, path }) => ({
                      className: this.state.selected ? (this.state.selected.node.id === node.id ? 'tree-selected' : 'tree-not-selected') : 'tree-normal',
                      buttons: nodeButtons(node, path),
                      title: (
                        <div
                          onClick={() =>
                            this.state.selected && (this.state.selected.path === path) 
                            ? this.setState({ selected: undefined})
                            : this.setState({ selected: { node, path }})
                          }
                        >
                            {!lockedDown(node) && node.title}
                          <div className={styles.treeBadge} style={{
                            backgroundColor: badgeColor[node.type],
                            ...(lockedDown(node) ? { color: 'black' } : {}),
                          }}>
                            {pretty(node.type)}
                          </div>
                          {node.hidden
                          ? <div className={styles.treeBadge} style={{
                            backgroundColor: 'blue'
                          }}>
                            Hidden
                          </div>
                          : null}
                        </div>
                      )
                    })}
                    onChange={this.onChange}
                    getNodeKey={getNodeKey}
                    canDrop={({ nextParent, ...rest }) => nextParent ? nextParent.type === 'submenu' : nextParent === null}
                    canDrag={({ node }) => !lockedDown(node)}
                />
            </div>
            <div className={`${styles.controls}`}>
              <button className={`${styles.controlButton} ${styles.new}`} onClick={this.addNew}>
                    Neue Slide einfügen
              </button>
              <button className={`${styles.controlButton} ${styles.upload}`}  style={{ color: '#fff', backgroundColor: 'green' }} onClick={this.upload}>
                    Speichern
              </button>
              <button className={`${styles.controlButton} ${styles.reload}`}  onClick={this.reload}>
                    Abbrechen
              </button>
            </div>
            <div className={`${this.state.selected ? styles.selectedContent : ''} ${styles.content}`}>
             {/*<button type='button' onClick={this.reset}>
                Reset
              </button>
              <button type='button' onClick={this.export}>
                Export
              </button>*/}
              {this.state.selected && this.renderSelected(this.state.selected)}
            </div>
        </div>
        <div className={uploading ? styles.uploadingModal : styles.hiddenModal}>
              <div className={styles.uploadingInnerDiv}>
                  {typeof uploading === 'string'
                  ? `Error: ${uploading}`
                  : 'uploading ...'} <br />
                  <button type='button' className={`${styles.uploadingButton}`} onClick={() => {
                    if (this._cancel) {
                      this._cancel()
                      this._cancel = null
                    } else {
                      this.setState({ uploading: false })
                    }
                  }}>
                    Cancel
                  </button>
                </div>
        </div>
        </React.Fragment>
    )
  }
}
