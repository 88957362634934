// @flow

import React from 'react'
import { convertFromRaw, convertToRaw } from 'draft-js'
import { stateToMarkdown } from "draft-js-export-markdown"
import { stateFromMarkdown } from 'draft-js-import-markdown'
import { DraftailEditor, ENTITY_TYPE, BLOCK_TYPE, INLINE_STYLE } from "draftail"
import createLinkifyPlugin from 'draft-js-linkify-plugin'

import Link from './RTF/Link'
import LinkSource from './RTF/LinkSource'

const linkifyPlugin = createLinkifyPlugin()

type Props = {
  markdown: string,
  setMarkdown: string => void,
}

type State = {
  contentState: any, 
}

// this is not a particularly good implementation, as the value & editor context might differ if not done properly
class MarkdownRTF extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props)
    this.state = {
      contentState: props.markdown ? stateFromMarkdown(props.markdown) : null,
    }
  }

  componentDidMount() {
    if (!this.props.markdown) {
      this.setState({
        contentState: null
      })
    } else {
      this.setState({
        contentState: stateFromMarkdown(this.props.markdown)
      })
    }
  }
   
   onSave = (contentState: any) => {
    const finish = convertFromRaw(contentState)
    this.setState({ contentState: finish  })
    this.props.setMarkdown(stateToMarkdown(
      finish
    ))
   }

   render () {
     const { contentState } = this.state
     return (
      <DraftailEditor
        rawContentState={contentState ? convertToRaw(contentState) : null}
        onSave={this.onSave}
        entityTypes={[
          {
            type: ENTITY_TYPE.LINK,
            source: LinkSource, 
            decorator: Link, 
            attributes: ["url"], 
            whitelist: { 
              href: "^(?![#/])", 
            }, 
          },
        ]}
        blockTypes={[
          { type: BLOCK_TYPE.HEADER_TWO },
          { type: BLOCK_TYPE.HEADER_THREE },
          { type: BLOCK_TYPE.UNORDERED_LIST_ITEM },
        ]}
        inlineStyles={[{ type: INLINE_STYLE.BOLD }, { type: INLINE_STYLE.ITALIC }]}
        plugins={[linkifyPlugin]}
      />
     )
   }
}

export default MarkdownRTF