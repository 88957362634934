// @flow
import React from 'react'
import type { RouterHistory, Match } from 'react-router'
import type { Tree, Language } from '../types'

import AONButton from './AON/Button'

const AON: {
  [string]: any,
} = {
  'internal#aon-button': AONButton,
}

type Props = {
  history: RouterHistory,
  match: Match,
  tree: Tree,
  lang: Language,
  fetchTree: () => void,
}

const AlwaysOn = ({ tree, ...rest }: Props) =>
  (tree: any)
  .filter(pd => AON[pd.type])
  .map(pd => (
    React.createElement(AON[pd.type], { ...pd, ...rest })
  ))

export default AlwaysOn