import React from 'react'
import { Route } from 'react-router'
import { connect } from 'react-redux'
import Screensaver, { Trigger as CTrigger } from '../components/Screensaver'
import { mapStateToProps, mapDispatchToProps } from './sharedSetup'

const ReduxTrigger = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CTrigger)

export const Trigger = (props: any) => 
  <Route render={(propsRouter) => <ReduxTrigger {...props} {...propsRouter} />} />

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Screensaver)
