// @flow

import type { Language } from '../types'

export const SET_LANG = 'SET_LANG'
export const SWITCH_LANG = 'SWITCH_LANG'

export const setLang = (lang: Language) => ({
  type: SET_LANG,
  lang
})

export const switchLang = () => ({
  type: SWITCH_LANG,
})