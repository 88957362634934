import 'react-sortable-tree/style.css'
import React from 'react'
import { render } from 'react-dom'
import Root from './containers/Root'
import { configureStore, history } from './store/configureStore'
import './app.global.css'

window.addEventListener('online',  function() { setTimeout(() => window.location.reload(), 1000) })

const { store } = configureStore()

render(
  <Root store={store} history={history} />,
  document.getElementById('root')
)