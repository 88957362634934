import React from 'react'
import { connect } from 'react-redux'
import AlwaysOn from '../components/AlwaysOn'

import { mapStateToProps, mapDispatchToProps } from './sharedSetup'

const AON = connect(
  mapStateToProps,
  mapDispatchToProps
)(AlwaysOn)

export const withAlwaysOn = (Component) => ({ children, ...props }) => (
  <React.Fragment>
    <Component {...props}>
      {children}
      <AON {...props} />
    </Component>
  </React.Fragment>
)

export default AON