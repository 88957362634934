// @flow
import React, { Component } from "react"

import { RichUtils, EditorState } from "draft-js"
import type { EntityInstance } from "draft-js"

import Modal from "./Modal"
import styles from '../Admin.module.css'
import "./RTF.css"

type Props = {
	editorState: EditorState,
	onComplete: EditorState => void,
	onClose: () => void,
	entityType: {
		type: string
	},
	entity: ?EntityInstance
};

type State = {
	url: string
};

class LinkSource extends Component<Props, State> {
	constructor(props: Props) {
		super(props)

		const { entity } = this.props
		const state = {
			url: ""
		}

		if (entity) {
			const data = entity.getData()
			state.url = data.url
		}

		this.state = state

		this.onRequestClose = this.onRequestClose.bind(this)
		this.onAfterOpen = this.onAfterOpen.bind(this)
		this.onConfirm = this.onConfirm.bind(this)
		this.onChangeURL = this.onChangeURL.bind(this)
	}

	/* :: onConfirm: (e: Event) => void; */
	onConfirm(e: Event) {
		const { editorState, entityType, onComplete } = this.props
		const { url } = this.state

		e.preventDefault()

		const contentState = editorState.getCurrentContent()

		const data = {
			url: url.replace(/\s/g, "")
		}
		const contentStateWithEntity = contentState.createEntity(
			// Fixed in https://github.com/facebook/draft-js/commit/6ba124cf663b78c41afd6c361a67bd29724fa617, to be released.
			// $FlowFixMe
			entityType.type,
			"MUTABLE",
			data
		)
		const entityKey = contentStateWithEntity.getLastCreatedEntityKey()
		const nextState = RichUtils.toggleLink(
			editorState,
			editorState.getSelection(),
			entityKey
		)

		onComplete(nextState)
	}

	/* :: onRequestClose: (e: SyntheticEvent<>) => void; */
	onRequestClose(e: SyntheticEvent<>) {
		const { onClose } = this.props
		e.preventDefault()

		onClose()
	}

	/* :: onAfterOpen: () => void; */
	onAfterOpen() {
		const input = this.inputRef

		if (input) {
			input.focus()
			input.select()
		}
	}

	/* :: onChangeURL: (e: Event) => void; */
	onChangeURL(e: Event) {
		if (e.target instanceof HTMLInputElement) {
			const url = e.target.value
			this.setState({ url })
		}
	}

	inputRef: ?HTMLInputElement;

	render() {
		const { url } = this.state
		return (
			<Modal
				onRequestClose={this.onRequestClose}
				onAfterOpen={this.onAfterOpen}
				isOpen
				contentLabel="Link chooser"
				appElement={document.getElementById("root")}>
				<form className="LinkSource">

          <span className="input">Link URL</span>
          <input
            ref={inputRef => {
              this.inputRef = inputRef
            }}
            className={styles.input}
            type="text"
            onChange={this.onChangeURL}
            value={url}
            placeholder="www.example.com"
          />

					<button type="button" className={styles.resourceButton} onClick={this.onConfirm}>
						Save
					</button>
				</form>
			</Modal>
		)
	}
}

export default LinkSource