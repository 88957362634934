// @flow
import { SET_TREE } from '../actions/tree'
import { SET_URL_HACK } from '../actions/url'
import type { Action, URLHack } from './types'

export default function (state: URLHack = null, action: Action) {
  switch (action.type) {
    case SET_URL_HACK:
      return action.url
    case SET_TREE:
      return null
    default:
      if (typeof action.type === 'string' && action.type.startsWith('@@router')) {
        return null
      }
      return state
  }
}
