// @flow
import React from 'react'

import type { TranslationFunc } from '../types'

type Props = {
  src?: string,
  t: TranslationFunc,
  goPrev?: () => void,
  goNext?: () => void
}

export default class Video extends React.Component<Props> {
  render() {
    const { src } = this.props 
    return (
      <React.Fragment>
        {src && <video className="fullscreen-video" width="100%" height="100vh" autoPlay loop controls>
          <source src={src} type="video/mp4"/>
        </video>}
      </React.Fragment>
    )
  }
}