// @flow

import React from 'react'
import ICalendar from './ICalendar'

import type { Language, Event, Resource } from '../types'

type Props = {
  title: string,
  titleEnglish?: string,
  lang: Language,

  topTextEnglish: string,
  topText: string,
  bottomTextEnglish: string,
  bottomText: string,
  events: Event[],
  resources: Resource[],
}

const tr = (lang: Language, english?: string, german: string) => (
  lang === 'en' && english ? english : german
)

const dummy = () => {}

export default class Video extends React.Component<Props> {
  render() {
    const { lang, events, resources } = this.props
    const { titleEnglish, title } = this.props
    const { topTextEnglish, topText } = this.props
    const { bottomTextEnglish, bottomText } = this.props
    return (
      <React.Fragment>
        <div className='calendar-title'>
          {tr(lang, titleEnglish, title)}
        </div>
        <div className='calendar-toptext'>
          {tr(lang, topTextEnglish, topText)}
        </div>
        <div className='calendar-content'>
          <ICalendar addEvent={dummy} setEvents={dummy} events={events} resources={resources} readOnly={true} />
        </div>
        <div className='calendar-bottomtext'>
          {tr(lang, bottomTextEnglish, bottomText)}
        </div>
      </React.Fragment>
    )
  }
}