// @flow
/* eslint-disable prefer-destructuring */

import React from 'react'
import type { Location, RouterHistory, Match } from 'react-router'
import type { Language, PossibleSlides, TreeNode, TranslationFunc, URLHack } from '../types'

//import bg from '../assets/schwaigerhof_bg.png'
import ChangeLang from './ChangeLang'

import Gallery from './Gallery'
import Video from './Video'
import Website from './Website'
import FAQ from './FAQ'
import CalendarSlide from './CalendarSlide'

import UiButton from './UiButton'

const lookup = (path: string[], tree: TreeNode[]): TreeNode[] => {
  if (path.length === 0) {
    return tree
  } 
  const ix = tree.findIndex((val) => val.id === path[0])
  if (ix === -1) {
    throw new Error("Invalid path")
  }
  const element: TreeNode = tree[ix]
  if (element.type !== 'submenu') {
    throw new Error("Invalid node found on path")
  }
  return lookup(path.slice(1), element.children)
}

type Props = {
  location: Location,
  history: RouterHistory,
  match: Match,
  node: PossibleSlides,
  tree: TreeNode[],
  fetchTree: () => void,
  urlHack: URLHack,
  setUrlHack: URLHack => void,
  t: TranslationFunc,
  lang: Language,
  path: string[]
}

export default class Slide extends React.Component<Props> {

  goPrev: (() => void) | void

  goNext: (() => void) | void

  renderSlide = (s?: PossibleSlides) => {
    const props = this.props
    const controls = this.lookupCurrentEnvironment()
    if (!s) return "invalid slide"
    switch (s.type) {
      case 'gallery':
        return (<Gallery {...controls} {...props} {...s} />)
      case 'video':
        return (<Video {...controls} {...props} {...s} />)
      case 'website': 
        return (<Website {...controls} {...props} {...s} />)
      case 'faq':
        return (<FAQ {...controls} {...props} {...s} />)
      case 'calendar':
        return (<CalendarSlide {...controls} {...props} {...s} />)
      default:
        return "invalid slide"
    }
  }

  goThisSlide = () => {
    const { history, path, node } = this.props
    const pathJ = path.join('/')
    // $FlowFixMe
    history.push(`/p/${pathJ ? `${pathJ}/` : ``}${node.id}`)
  }
  goParent = () => {
    const { history, path } = this.props
    if (path.length === 0) history.push('/')
    else history.push(`/p/${path.join('/')}`)
  }

  lookupCurrentEnvironment = () => {
    const go = (id?: string, tag: string) => () => {
      if (!id) return
      const { history, path } = this.props
      const pathUrl = path.join('/')
      history.push({
        pathname: `/p/${pathUrl}/${id}`,
        hash: tag
      })
    }

    const { node, path, tree } = this.props
    if (path.length === 0) return {}
    try {
      const xs = lookup(path, tree)
      const ix = xs.findIndex((val) => val.id === node.id)
      if (ix === -1) throw new Error("Invalid final path")
      const prev = xs[ix-1] || null
      const next = xs[ix+1] || null
      return {
        goPrev: prev ? go(prev.id, 'prev') : undefined,
        goNext: next ? go(next.id, 'next') : undefined
      }
    } catch (err) {
      console.error({
        node, path, tree,
        err
      })
      return {}
    }
  }

  render() {
    const { t, node } = this.props
    return (
      <div style={{
        width: '100vw',
        height: '100vh',
        //background: `url(${bg})`,
        background: '#fff',
        color: '#111'
      }}>
        <ChangeLang {...this.props} style={{ fontSize: 32, left: (node && node.list && node.list[0] && node.list[0].url && node.list[0].url.includes('schladming-dachstein.at')) ? 116 : 16 }} />
        {/*<div className="slidetitle">{node.title}</div>*/}
        <div>{this.renderSlide(node)}</div>
        <UiButton className='backbtn' onClick={() => {
          window.ga('send', {
            hitType: 'event',
            eventCategory: 'BackButton',
            eventAction: 'click',
            eventLabel: 'Slide'
          })
          this.goParent()
        }}>
          {'<'} {t('back')}
        </UiButton>
      </div>
    )
  }
}