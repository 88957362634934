// @flow

/*
  This code provides some basic wrapping over react-big-scheduler
  As it has quite a lot of issues, but it seems to be the most feature-complete calendar out there
*/

import * as React from 'react'

import Scheduler, { SchedulerData, ViewTypes } from 'react-big-scheduler'
import type { Resource, Event } from '../types'

export type EventHelpers = {
  addEvent: (Event[], Event) => Event[],
}
export type CalendarProps = {
  track?: number,
  resources: Resource[],
  events: Event[],
  width?: string | number,
  readOnly: boolean,
  addEvent: (Event, EventHelpers) => void,
  setEvents: Event[] => void,
  eventClicked?: (Event) => void,
  deleteClicked?: (Event) => void,
  changeColor?: Event => void,
}

class Calendar extends React.Component<CalendarProps> {

  schedulerData: any

  constructor(props: CalendarProps) {
    super(props)
    this.schedulerData = new SchedulerData()
    if (props.width) {
      this.schedulerData.config = { ...this.schedulerData.config, schedulerWidth: props.width }
    }
    this.schedulerData.setResources(props.resources)
    this.schedulerData.setEvents(props.events)
  }

  getSnapshotBeforeUpdate(oldProps: CalendarProps) {
    const { resources, events } = this.props
    this.schedulerData.setResources(resources)
    this.schedulerData.setEvents(events)

    return null
  }

  componentDidUpdate(oldProps: CalendarProps) {
  }

  render() {
    console.warn(this.schedulerData, this.schedulerData.resources)
    if (this.props.readOnly) {
      return (
        <Scheduler
          key={this.props.track || 'scheduler'}
          schedulerData={this.schedulerData}
          prevClick={this.prevClick}
          nextClick={this.nextClick}
          onSelectDate={this.onSelectDate}
          onViewChange={this.onViewChange}
          updateEventStart={this.updateEventStart}
          updateEventEnd={this.updateEventEnd}
          moveEvent={this.moveEvent}
          newEvent={this.newEvent}
          onScrollLeft={this.onScrollLeft}
          onScrollRight={this.onScrollRight}
          onScrollTop={this.onScrollTop}
          onScrollBottom={this.onScrollBottom}
          toggleExpandFunc={this.toggleExpandFunc}
        />
      )
    } else {
      return (
        <Scheduler
          key={this.props.track || 'scheduler'}
          schedulerData={this.schedulerData}
          prevClick={this.prevClick}
          nextClick={this.nextClick}
          onSelectDate={this.onSelectDate}
          onViewChange={this.onViewChange}
          eventItemClick={this.eventClicked}
          viewEventClick={this.ops1}
          viewEventText="Delete"
          viewEvent2Text="Change Color"
          viewEvent2Click={this.ops2}
          updateEventStart={this.updateEventStart}
          updateEventEnd={this.updateEventEnd}
          moveEvent={this.moveEvent}
          newEvent={this.newEvent}
          onScrollLeft={this.onScrollLeft}
          onScrollRight={this.onScrollRight}
          onScrollTop={this.onScrollTop}
          onScrollBottom={this.onScrollBottom}
          toggleExpandFunc={this.toggleExpandFunc}
        />
      )
    }
  }
  
  update = (schedulerData: any, upLift?: boolean) => {
    schedulerData.setEvents(this.props.events)
    if (this.props.readOnly) {
    } else if (upLift) {
      this.props.setEvents(schedulerData.events)
    }
    this.forceUpdate()
  }

  prevClick = (schedulerData: any) => {
    schedulerData.prev()
    this.update(schedulerData)
  }

  nextClick = (schedulerData: any) => {
    schedulerData.next()
    this.update(schedulerData)
  }

  onViewChange = (schedulerData: any, view: any) => {
    schedulerData.setViewType(view.viewType, view.showAgenda, view.isEventPerspective)
    this.update(schedulerData)
  }
  onSelectDate = (schedulerData: any, date: any) => {
    schedulerData.setDate(date)
    this.update(schedulerData)
  }

  eventClicked = (schedulerData: any, event: any) => {
    if (this.props.eventClicked) {
      this.props.eventClicked(event)
    }
  }

  ops1 = (schedulerData: any, event: any) => {
    if (this.props.deleteClicked) {
      this.props.deleteClicked(event)
    }
  }

  ops2 = (schedulerData: any, event: any) => {
    if (this.props.changeColor) {
      this.props.changeColor(event)
    }
  }

  newEvent = (schedulerData: any, slotId: any, slotName: any, start: any, end: any, type: any, item: any) => {
    if (this.props.readOnly) return
    let newFreshId = 0
    schedulerData.events.forEach((item: any) => {
        if(item.id >= newFreshId)
            newFreshId = item.id + 1
    })

    let newEvent = {
        id: newFreshId,
        title: 'new event',
        start: start,
        end: end,
        resourceId: slotId,
        bgColor: 'purple'
    }
    const addEventCb = (events, event) => {
      let pos = 0
      let eventStart = schedulerData.localeMoment(event.start)
      events.forEach((item, index) => {
          let start = schedulerData.localeMoment(item.start)
          if(eventStart >= start)
              pos = index + 1
      })
      const events2 = [...events]
      events2.splice(pos, 0, event)
      return events2
    }
    this.props.addEvent(newEvent, { addEvent: addEventCb })
  }

  updateEventStart = (schedulerData: any, event: any, newStart: any) => {
    if (this.props.readOnly) return
    schedulerData.updateEventStart(event, newStart)
    this.update(schedulerData, true)
  }

  updateEventEnd = (schedulerData: any, event: any, newEnd: any) => {
    if (this.props.readOnly) return
    schedulerData.updateEventEnd(event, newEnd)
    this.update(schedulerData, true)
  }

  moveEvent = (schedulerData: any, event: any, slotId: any, slotName: any, start: any, end: any) => {
    if (this.props.readOnly) return
    schedulerData.moveEvent(event, slotId, slotName, start, end)
    this.update(schedulerData, true)
  }
  onScrollRight = (schedulerData: any, schedulerContent: any, maxScrollLeft: any) => {
    if(schedulerData.ViewTypes === ViewTypes.Day) {
        schedulerData.next()
        this.update(schedulerData)
        schedulerContent.scrollLeft = maxScrollLeft - 10
    }
  }

  onScrollLeft = (schedulerData: any, schedulerContent: any, maxScrollLeft: any) => {
      if(schedulerData.ViewTypes === ViewTypes.Day) {
          schedulerData.prev()
          this.update(schedulerData)
          schedulerContent.scrollLeft = 10
      }
  }

  onScrollTop = (schedulerData: any, schedulerContent: any, maxScrollTop: any) => {
      console.log('onScrollTop')
  }

  onScrollBottom = (schedulerData: any, schedulerContent: any, maxScrollTop: any) => {
      console.log('onScrollBottom')
  }

  toggleExpandFunc = (schedulerData: any, slotId: any) => {
      schedulerData.toggleExpandStatus(slotId)
      this.update(schedulerData)
  }
}

export default Calendar