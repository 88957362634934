/* eslint-disable jsx-a11y/iframe-has-title */
// @flow
import React from 'react'
import type { Location, RouterHistory, Match } from 'react-router'
import UiButton from './UiButton'

import type { TranslationFunc, Language } from '../types'

const InvalidWeb = ({ error }: { error?: string }) => {
  return (
    <div className="submenu submenutitle  ">
      {error || "Invalid webpage"}
    </div>
  )
}

type Props = {
  location: Location,
  history: RouterHistory,
  match: Match,
  t: TranslationFunc,
  lang: Language,
}

type State = {
  error?: string,
}

export default class Website extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props)
    this.state = {
    }
  }

  getData = () => {
    try {
      const { location } = this.props
      const { state } = location
      return state
    } catch (err) {
      return {}
    }
  }

  goBack = () => {
    const { history } = this.props
    const data = this.getData()
    if (data && data.previous) {
      history.push(data.previous)
    }
  }

  render() {
    const { t } = this.props
    const { error } = this.state
    const data = this.getData()
    return (
      <React.Fragment>
        {(data && data.url && !error)
          ? <iframe className="fullscreen-website" src={data.url} />
          : <InvalidWeb error={error} />}
        <UiButton className='backbtn' onClick={() => {
          window.ga('send', {
            hitType: 'event',
            eventCategory: 'BackButton',
            eventAction: 'click',
            eventLabel: 'FullscreenWebsite'
          })
          this.goBack()
        }}>
          {'<'} {t('back')}
        </UiButton>
      </React.Fragment>
    )
  }
}