// @flow
import React from 'react'
import { Link } from 'react-router-dom'
import type { Match, RouterHistory } from 'react-router'
import type { Submenu, TreeNode, TranslationFunc, Language } from '../types'
import UiButton from './UiButton'
import { CoreButton } from './MainButton'

//import bg from '../assets/schwaigerhof_bg.png'

type Props = {
  history: RouterHistory,
  match: Match,
  path: string[],
  node: Submenu,
  lang: Language,
  t: TranslationFunc,
}

const LinkButton = CoreButton(Link)

export default class SubMenu extends React.Component<Props> {
    props: Props

    goParent = () => {
      const { history, path } = this.props
      if (path.length === 0) history.push('/')
      else history.push(`/p/${path.join('/')}`)
    }

    render () {
      const { t, match, node, lang } = this.props
      const { title, children } = node
      return (
        <React.Fragment>
          <div className="submenu" style={{
              width: '100vw',
              height: '100vh',
              /*background: `url(${bg})`,*/
              background: '#fff',
              color: '#111'
            }}
          >
            <div className="submenutitle" key="title">
                <h1>{title}</h1>
            </div>
            <div key="entries" className="entries">
                { children.map((e: TreeNode) => e.id && e.hidden !== true &&
                  <LinkButton key={e.id} className="mainbtn" title={lang === 'en' && e.titleEnglish ? e.titleEnglish : e.title} titleType={e.titleType} titleContent={e.titleContent} to={`${match.url}/${e.id}`} divStyle={{
                    marginRight: '40px'
                  }}/>
                )}
            </div>
            <UiButton className='backbtn' onClick={() => {
              window.ga('send', {
                hitType: 'event',
                eventCategory: 'BackButton',
                eventAction: 'click',
                eventLabel: 'SubMenu'
              })
              this.goParent()
            }}>
              {'<'} {t('back')}
            </UiButton>
          </div>
        </React.Fragment>
      )
    }
}