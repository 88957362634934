/* eslint-disable jsx-a11y/iframe-has-title */
// @flow
import React, { useState } from 'react'
import type { Location, RouterHistory } from 'react-router'
import styles from './Button.module.css'
import { useRestartMagic, getPreloadPath } from '../Website'

type ButtonProps = {
  location: Location,
  history: RouterHistory,
  side: 'bottom-right',
  src: string,
  url: string,
}

const Button = ({ history, location, side, url, src }: ButtonProps) => {
  const [visible, setVisible] = useState(false)
  const setRef = useRestartMagic()
  const onClick = () => {
    setVisible(true)
    /* history.push({
      pathname: '/web',
      state: {
        url: url,
        previous: location.pathname
      },
    }) */
  }
  return (
    <React.Fragment>
      <div
        className={`${styles.aonButton}`}
        style={{
          backgroundImage: `url('${src}')`,
          backgroundSize: '100% 100%',
        }} 
        onClick={onClick}
      />
      {visible && (
        <React.Fragment>
          <div className={`${styles.cover}`} onClick={() => setVisible(false)} />
          <div className={`${styles.popup}`}>
            <webview ref={setRef} preload={getPreloadPath()} className={`${styles.popupIframe}`} src={url} />
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

export default Button