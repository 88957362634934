// @flow
import { SET_LANG, SWITCH_LANG } from '../actions/lang'
import type { Action } from './types'
import type { Language } from '../types'

export default function (state: Language = 'de', action: Action) {
  switch (action.type) {
    case SET_LANG:
      return action.lang
    case SWITCH_LANG:
      return state === 'de' ? 'en' : 'de'
    default:
      return state
  }
}
