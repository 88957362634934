// @flow
import React, { Component } from "react"
import type { Node } from "react"
import ReactDOM from "react-dom"

type DefaultProps = {|
	children?: Node,
	closeOnClick: boolean,
	closeOnType: boolean,
	closeOnResize: boolean
|};

type Props = {|
	...DefaultProps,
	onClose: () => void
|};

class Portal extends Component<Props, {}> {
	static defaultProps: DefaultProps;

	constructor(props: Props) {
		super(props)

		this.onCloseEvent = this.onCloseEvent.bind(this)
	}

	componentDidMount() {
		const {
			onClose,
			closeOnClick,
			closeOnType,
			closeOnResize
		} = this.props

		if (!this.portal) {
			this.portal = document.createElement("div")

			if (document.body) {
				document.body.appendChild(this.portal)
			}

			if (onClose) {
				if (closeOnClick) {
					document.addEventListener("mouseup", this.onCloseEvent)
				}

				if (closeOnType) {
					document.addEventListener("keyup", this.onCloseEvent)
				}

				if (closeOnResize) {
					window.addEventListener("resize", onClose)
				}
			}
		}

		this.componentDidUpdate()
	}

	componentDidUpdate() {
		const { children } = this.props

		ReactDOM.render(<div>{children}</div>, this.portal)
	}

	componentWillUnmount() {
		const { onClose } = this.props

		if (document.body) {
			document.body.removeChild(this.portal)
		}

		document.removeEventListener("mouseup", this.onCloseEvent)
		document.removeEventListener("keyup", this.onCloseEvent)
		window.removeEventListener("resize", onClose)
	}

	/* :: onCloseEvent: (e: Event) => void; */
	onCloseEvent(e: Event) {
		const { onClose } = this.props

		if (e.target instanceof Element && !this.portal.contains(e.target)) {
			onClose()
		}
	}

	portal: Element;

	render() {
		return null
	}
}

Portal.defaultProps = {
	children: null,
	closeOnClick: false,
	closeOnType: false,
	closeOnResize: false
}

export default Portal
