import React from 'react'
import { Switch, Route } from 'react-router'
import type { Location } from 'react-router'
import { TransitionGroup, CSSTransition } from "react-transition-group"

import Admin from './containers/AdminPage'
import HomePage from './containers/HomePage'  
import PageRenderer from './containers/PageRenderer'
import Screensaver from './containers/Screensaver'
import WebsiteIndep from './containers/WebsiteIndep'
import { withAlwaysOn } from './containers/AlwaysOn'

const Routes = {
  'HOME': '/',
  'ADMIN': '/admin',
  'PAGES': '/p',
  'PAGES_INTERNAL': '/int',
  'WEB': '/web',
  'SCREENSAVER': '/screensaver'
}

const HomePageP = withAlwaysOn(HomePage)
const PageRendererP = PageRenderer

const Keysystem = ({ history, location }: { history: RouterHistory, location: Location }) => {
  return (
    <Route
      render={({ location }) => (
        <TransitionGroup>
          <CSSTransition
            key={location.key}
            classNames='page-change'
            timeout={800}
          >
            <Switch location={location}>
              <Route path={Routes.ADMIN} component={Admin} />
              <Route path={Routes.PAGES} component={PageRendererP} />
              <Route path={Routes.PAGES_INTERNAL} component={PageRenderer} />
              <Route path={Routes.SCREENSAVER} component={Screensaver} />
              <Route path={Routes.WEB} component={WebsiteIndep} />
              <Route path={Routes.HOME} component={HomePageP} />
            </Switch>
          </CSSTransition>
        </TransitionGroup>
      )}
    />
  )
}

export default () => (
    <Route render={Keysystem} />
)
