// @flow
import React, { Component } from "react"
import type { Node } from "react"
import type { RouterHistory, Match } from "react-router"
import { lockedDown } from "../utils/keys"

import bgWithHeader from "../assets/schwaigerhof_bg_main.png"
import MainButton from "./MainButton"
//import HomeBanner from './HomeBanner'
import { DigiGastWidget } from "./DigiGastWidget"
import "./Home.css"

import type { Tree, Language } from "../types"
import { Trigger as ScreensaverTrigger } from "../containers/Screensaver"
import ChangeLang from "./ChangeLang"

type Props = {
  history: RouterHistory,
  match: Match,
  tree: Tree,
  lang: Language,
  fetchTree: () => void,
  children: Node,
};

export default class Home extends Component<Props> {
  props: Props;

  onSelect(id: string) {
    const { history } = this.props
    // id => index of the selected Element in the wheel
    if (id) {
      history.push(`/p/${id}`)
    } else {
      console.error(`The id ${id} is not supported`)
    }
  }

  render() {
    const { tree, lang, children } = this.props
    const banner = tree.find((x) => x.type === "internal#banner")
    const bannerOn =
      banner && Array.isArray(banner.content) && banner.content.length > 0
    if (tree.length === 0) {
      this.props.fetchTree()
    }
    return (
      <ScreensaverTrigger>
        <div style={{ background: `url(${bgWithHeader})`, height: "100vh" }}>
          <ChangeLang {...this.props} />
          <div
            onClick={() => window.location.reload()}
            style={{
              color: "white",
              position: "absolute",
              left: 16,
              bottom: 7,
              fontSize: 32,
              opcaity: 0.8,
            }}
          >
            ↻
          </div>
          <h1
            style={{
              textAlign: "center",
              color: "#fff",
              marginTop: 0,
              paddingTop: "0.67em",
              visibility: "hidden",
            }}
          >
            .
          </h1>
          <div className="main-div">
            <div
              className={
                bannerOn ? "main-container-with-banner" : "main-container"
              }
              data-tid="container"
            >
              {tree.map((el) =>
                lockedDown(el) || el.hidden === true ? null : (
                  <MainButton
                    titleType={el.titleType}
                    titleContent={el.titleContent}
                    title={
                      lang === "en" && el.titleEnglish
                        ? el.titleEnglish
                        : el.title
                    }
                    onClick={() => {
                      window.ga("send", {
                        hitType: "event",
                        eventCategory: "Button",
                        eventAction: "click",
                        eventLabel: el.title,
                      })
                      // $FlowFixMe
                      this.onSelect(el.id)
                      console.warn(el)
                    }}
                  />
                )
              )}
            </div>
          </div>
          {/*bannerOn && <HomeBanner {...banner} />*/}
          {bannerOn && <DigiGastWidget lang={lang} />}
        </div>
        {children}
      </ScreensaverTrigger>
    )
  }
}
