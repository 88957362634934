// @flow
import { bindActionCreators } from 'redux'
import { setupTranslation } from '../utils/translations'
import * as langActions from '../actions/lang'
import * as treeActions from '../actions/tree'
import * as urlActions from '../actions/url'

export function mapStateToProps(state: any) {
  return {
    tree: state.tree,
    lang: state.lang,
    urlHack: state.urlHack,
    t: setupTranslation(state.lang),
  }
}

export function mapDispatchToProps(dispatch: any) {
  return bindActionCreators({
    ...langActions,
    ...treeActions,
    ...urlActions,
  }, dispatch)
}