// @flow
import type { Tree } from '../types'
import axios from 'axios'
import config from '../config.json'

export const SET_TREE = 'SET_TREE'

/* this sets the tree locally, to do a server-side update, you must do the request from the admin menu */
export const setTree = (tree: Tree) => ({
  type: SET_TREE,
  tree
})

export const fetchTree = () => (dispatch: any) => {
  return axios.get(`${config.apiUrl}slides`)
      .then(({ data }) => {
       dispatch(setTree(data))
     })
}