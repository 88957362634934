// @flow
import React from 'react'
import type { Location, RouterHistory, Match } from 'react-router'
import { Route } from 'react-router-dom'
import type { Image, TranslationFunc } from '../types'
import SImage from './Image'

type Props = {
  location: Location,
  history: RouterHistory,
  match: Match,
  content: Image[],
  t: TranslationFunc,
  goPrev?: () => void,
  goNext?: () => void
}

export const Gallery = ({ t, history, match, content }: Props) => {
  if (content.length === 1) {
    return (
      <SImage src={content[0]} />
    )
  }
  return (<Route path={`${match.url}/:ix?`} render={(props) => {
    let ix = parseInt(props.match.params.ix, 10) || 0
    const goTo = (index: number) => {
      if (index < 0 || index >= content.length) return
      history.push(`${match.url}/${index}`)
    }
    const src = content[ix] || content[0]
    return (
      <React.Fragment>
        <div className='picture' style={{ backgroundImage: `url('${src}')` }} ></div>
        <div className={`uibtn control-left ${ix <= 0 ? 'disabled-btn': ''}`} onClick={() => goTo(ix-1)}>
          {'<'}
        </div>
        <div className='control-info'>
          {ix+1}/{content.length}
        </div>
        <div className={`uibtn control-right ${ix >= (content.length-1) ? 'disabled-btn': ''}`} onClick={() => goTo(ix+1)}>
          {'>'}
        </div>
      </React.Fragment>
    )
  }} />)
}

export default Gallery