// @flow
import React from 'react'

type Props = {
  src: string,
}

export default class Image extends React.Component<Props> {
  render() {
    const { src } = this.props
    return (
      <React.Fragment>
        <div className="fullscreen-image" style={{ backgroundImage: `url('${src}')` }} />
      </React.Fragment>
    )
  }
}