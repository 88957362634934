// @flow

import * as React from 'react'

type Props = any

const ChangeLangButton = ({ switchLang, t, style }: Props) => (
  <div className='switchlang uibtn' style={style} onClick={() => {
    window.ga('send', {
      hitType: 'event',
      eventCategory: 'Button',
      eventAction: 'click',
      eventLabel: 'switch_from_' + t('current_lang')
    })
    switchLang()
  }}>
    {t('current_lang')}
  </div>
)
export default ChangeLangButton