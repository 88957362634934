/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect } from 'react'

const HTMLThing = ({ html }) => {
  let x = html
  const extractscript=/<script>(.+)<\/script>/gi.exec(x)
  x=x.replace(extractscript[0],"")
  useEffect(() => {
    const existingWeatherScript = document.getElementById('weatherwidget-io-js')
    if (existingWeatherScript) {
      existingWeatherScript.remove()
    }
    window.eval(extractscript[1]) // eslint-disable-line
  })
  return (
    <div style={{
      width: '100%',
      height: '100%',
      backgroundColor: '#1f567c', // hard-coded color for weather
      pointerEvents: 'none',
    }}>
      <div dangerouslySetInnerHTML={{__html: x}} />
    </div>
  )
}
export const CoreButton = (Component) => ({ title, titleType, titleContent, divStyle, ...props }) => 
    <Component style={{
      ...(
        titleType === 'img' && titleContent
        ? {
          background: `url(${titleContent})`,
          backgroundSize: '100% 100%',
        } : {}),
      ...(divStyle ? divStyle : {})
    }} {...props}>
      {titleType === 'html' && titleContent && <HTMLThing html={titleContent} />}
      {titleType === 'video' && titleContent && 
        <video style={{
          position: 'absolute',
          top: '0%',
          left: '0%',
          width: '490px',
          height: 'auto',
          pointerEvents: 'none',
        }} autoplay="true" loop muted playsinline>
          <source src={titleContent} type="video/mp4" />
        </video>}
        {titleType === 'url' && titleContent &&
          <iframe title={`ik${title}-iframe`} frameborder='0' style={{
            position: 'absolute',
            top: '0%',
            left: '0%',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgb(100, 125, 150)', // hard-coded color for consistency reasons to show while it loads
            pointerEvents: 'none',
          }} src={titleContent} />}
      <div className='main-title'>
        {title}
      </div>
    </Component>

const MainButton = CoreButton((props) => <div className='mainbtn' {...props} />)

export default MainButton