// @flow
import React, { useState, useLayoutEffect, useContext } from 'react'
import type { FullURL, TranslationFunc, Language, URLHack } from '../types'
import type { Location, RouterHistory, Match } from 'react-router'
import UiButton from './UiButton'
import { ScreensaverCtx } from './Screensaver'

export const getPreloadPath = () => {
  try {
    const remote = window.require('electron').remote
    const appPath = remote.app.getAppPath()
    return `file://${appPath}/preload.js`
  } catch (err) { 
    return '/'
  }
}

type Props = {
  location: Location,
  history: RouterHistory,
  match: Match,
  t: TranslationFunc,
  lang: Language,
  urlHack: URLHack,
  setUrlHack: URLHack => void,
  list: FullURL[],
  goPrev?: () => void,
  goNext?: () => void,
}

export const useRestartMagic = () => {
  const ctx = useContext(ScreensaverCtx)
  const [ref,setRef] = useState(null)
  useLayoutEffect(() => {
    const restart = () => ctx.restart ? ctx.restart() : null
    console.warn({ ref, ctx })
    if (ref !== null) {
        const webview = ref
        const listenerWebview = function () {
          webview.executeJavaScript(`
            try {
              document.body.onclick = window.int_restartScreensaver
            } catch (err) {
              /* alert(err.message); */
            }
          `)
        }
        const listenerIpc = (event: any, data: any) => {
          if (event.channel === 'screensaver-event') {
            restart()
          }
        }
        webview.addEventListener('dom-ready', listenerWebview)
        webview.addEventListener('ipc-message', listenerIpc)
        return () => {
          webview.removeEventListener('dom-ready', listenerWebview)
          webview.removeEventListener('ipc-message', listenerIpc)
        }
    }
  }, [ref, ctx])
  return (ref: any) => setRef(ref)
}

const WebsiteUnique = ({ lang, urlHack, urlEnglish, url }: { lang: Language, urlHack: URLHack, urlEnglish?: string, url: string }) => {
  const ref = useRestartMagic()
  const finalSrc = urlHack
    ? urlHack
    : (lang === 'en' && urlEnglish ? urlEnglish : url)
  console.warn(__dirname)
  return (
    <webview ref={ref} preload={getPreloadPath()} key={lang} title='fullscreen-website' className="fullscreen-website" src={finalSrc} />
  )
}
type ListProps = {
  t: TranslationFunc,
  lang: Language,
  urlHack: URLHack,
  setUrlHack: URLHack => void,
  list: FullURL[],
}
const WebsiteList = (props: ListProps) => {
  const [active, setActive] = useState(0)
  const ref = useRestartMagic()

  const select = (ix) => {
    props.setUrlHack(null)
    setActive(ix)
  }

  const { t, lang, urlHack, list } = props
  const current = list[active]
  if (!current) return null
  const finalSrc = urlHack
    ? urlHack
    : (lang === 'en' && current.urlEnglish ? current.urlEnglish : current.url)
  return (
    <React.Fragment>
      <webview ref={ref} key={active} preload={getPreloadPath()} frameborder="0" title='windowed-website' className='windowed-website' src={finalSrc} />
      <div className='website-list'>
        {list.map((el, ix) => (
          <UiButton key={el.title} className={(ix === active) ? 'active btnweb' : 'btnweb'} onClick={() => select(ix)}>
            {t(el.title)}
          </UiButton>
        ))}
      </div>
    </React.Fragment>
  )

}

export default class Website extends React.Component<Props> {
  render() {
    const { t, lang, list, setUrlHack, urlHack } = this.props
    return (
      <React.Fragment>
        {list.length === 1
          ? <WebsiteUnique urlHack={urlHack} setUrlHack={setUrlHack} lang={lang} urlEnglish={list[0].urlEnglish} url={list[0].url} />
          : <WebsiteList urlHack={urlHack} setUrlHack={setUrlHack} lang={lang} list={list} t={t} />
        }
      </React.Fragment>
    )
  }
}
