/* eslint-disable jsx-a11y/anchor-has-content */
// @flow

import React, { useState } from 'react'
import Markdown from 'react-markdown'

import type { Language, TranslationFunc, EntryFAQ } from '../types'

type Props = {
  t: TranslationFunc,
  lang: Language,
  questions: Array<EntryFAQ>,
}

const FAQ = ({ t, lang, questions }: Props) => {
  const [active, setActive] = useState(null)
  const [currentLink, setLink] = useState(null)
  const flip = (ix) => {
    if (ix === active) setActive(null)
    else setActive(ix)
  }
  const openLink = (url) => {
    console.warn(`opening ${url}`)
    setLink({
      url
    })
  }
  const renderers = {
    'link': ({ title, href, children }) => (
      <div className="linkLooks" title={'test ' + title} onClick={() => openLink(href)}>
        {children}
      </div>
    )
  }
  return (
    <React.Fragment>
      <div className={`whole-faq ${currentLink ? 'blocked' : ''}`}>
        <div className='faq-title'>
          {t('faq-title')}
        </div>
        {questions.map((v, ix) => (
          <div className='faq-entry'>
            <div className='faq-question' onClick={() => {
              window.ga('send', {
                hitType: 'event',
                eventCategory: 'Faq',
                eventAction: 'expand',
                eventLabel: v.question['de']
              })
              flip(ix)
            }}>
              <Markdown escapeHtml={false} source={v.question[lang]} renderers={renderers} />
              <div className='faq-question-expand'>
                {ix === active ? '▼' : '▶'}
              </div>
            </div>
            <div className={`${ix === active ? 'shown' : 'hidden'} faq-answer`}>
              <Markdown escapeHtml={false} source={v.answer[lang]} renderers={renderers} />
            </div>
          </div>
        ))}
      </div>
      <div className={`modal-page ${currentLink ? 'shown' : 'hidden'}`}>
          {currentLink && <iframe className='modal-page-iframe' title='page' src={currentLink.url} />}
          <div className={`closeButton`} onClick={() => setLink(null)}>
            X
          </div>
      </div>
    </React.Fragment>
  )
}
export default FAQ