// @flow

import React, { useState, useEffect } from 'react'
import { Route } from 'react-router'
import type { Location, RouterHistory, Match } from 'react-router'
import Image from './Image'

type Props = {
  fetchTree: () => void,
  tree: any,
  location: Location,
  history: RouterHistory,
  match: Match,
}

type WrappedProps = {
  location: Location,
  history: RouterHistory,
  match: Match,
  content: string[],
  intervalSeconds: number,
}
type TriggerProps = {
  history: RouterHistory,
  tree: any,
  children: any,
}

const Screensaver = ({ history, content, intervalSeconds }: WrappedProps) => {
  const [current, setCurrent] = useState(0)
  useEffect(() => {
    const nextPicture = () => {
      const next = current + 1
      setCurrent(content.length === next ? 0 : next)
    }
    let timeout = setTimeout(nextPicture, intervalSeconds * 1000)
    return (() => {
      clearTimeout(timeout)
    })
  }, [content.length, current, intervalSeconds])

  return (<div className='screensaver' onClick={() => history.push('/')}>
    <Image src={content[current]} />
  </div>)
}

type SCtx = {
  restart: null | () => mixed
}
export const ScreensaverCtx = React.createContext<SCtx>({ restart: null })

export class Trigger extends React.Component<TriggerProps> {
  timeoutId: any

  constructor(props: TriggerProps) {
    super(props)
    this.timeoutId = null
    console.error(props)
  }

  restart = () => {
    const { tree, history } = this.props
    const screensaver = tree.find((v) => v.id === 'internal#screensaver')
    console.warn('restarting', tree, history, screensaver)
    if (this.timeoutId) {
      clearTimeout(this.timeoutId)
    }
    this.timeoutId = null
    if (screensaver && screensaver.timeout) {
      this.timeoutId = setTimeout(() => this.goScreensaver(screensaver), Number(screensaver.timeout)*1000)
    }
  }

  goScreensaver = (screensaver: any) => {
    const { history } = this.props
    if (screensaver && screensaver.goHome) {
      // only go to home if we are on another page
      // INFO: currently disabled this again ad this might quickfix another issue
      //if (history.location.pathname !== '/') {
      history.push('/')
      //}
    } else {
      history.push('/screensaver')
    }
  }
  componentDidMount() {
    this.restart()
  }
  componentDidUpdate(prevProps: TriggerProps) {
    const screensaverOld = prevProps.tree.find((v) => v.id === 'internal#screensaver')
    const screensaverNew = this.props.tree.find((v) => v.id === 'internal#screensaver')
    if (screensaverOld !== screensaverNew) {
      this.restart()
    }
  }
  componentWillUnmount() {
    clearTimeout(this.timeoutId)
  }

  render() {
    return (
      <ScreensaverCtx.Provider value={{ restart: this.restart }}>
        <div className='screensaver-trigger' onClick={() => this.restart()}>
          {this.props.children}
        </div>
      </ScreensaverCtx.Provider>
    )
  }
}

const ScreensaverWrapper = ({ tree, ...props }: Props) => {
  if (tree.length === 0) {
    props.fetchTree()
  }
  const screensaver = tree.find((v) => v.id === 'internal#screensaver')
  if (screensaver && screensaver.content && screensaver.intervalSeconds) {
    return (
      <Route render={propsR => (
        <Screensaver
          {...props}
          {...propsR}
          content={screensaver.content}
          intervalSeconds={Number(screensaver.intervalSeconds)}
          />
      )} />
    )
  } else {
    return null
  }
}
export default ScreensaverWrapper
