// @flow
import React from 'react'
import type { Node } from 'react'
import { connect } from 'react-redux'
import { Route } from 'react-router'
import type { Match } from 'react-router'
import type { Tree, TreeNode } from '../types'
import { mapStateToProps, mapDispatchToProps } from './sharedSetup'
import SubMenu from './SubMenu'
import Slide from './Slide'
import { Trigger as ScreensaverTrigger } from './Screensaver'
//import { withAlwaysOn } from './AlwaysOn'

type Props = {
  match: Match,
  tree: Tree,
  children: Node,
}

const SlideNotFound = connect(
  mapStateToProps,
  mapDispatchToProps
)(({ code, tree, fetchTree }) => {
  if (tree.length === 0) {
    // if the tree is empty, it might mean we haven't loaded it yet
    fetchTree()
  }
  return (<p>slide {code} not found</p>)
})

const RouteRender = (path: string[], tree: TreeNode[]) => ({ match, ...props }: { match: Match }) => {
  const el = tree.find(val => val.id === match.params.id)
  console.warn(el)
  if (el && el.id) {
    switch (el.type) {
      case 'submenu':
        return (
          <React.Fragment>
            {match.isExact && <SubMenu match={match} {...props} path={path} node={el} />}
            <Route path={`${match.url}/:id`} render={RouteRender([...path, el.id], el.children)} />
          </React.Fragment>
        )
      default:
          return (
            <Slide match={match} {...props} path={path} node={el} />
          )
    }
  }
  return (
    <React.Fragment>
      {match.isExact && <SlideNotFound code={match.params.id} />}
      <Route path={`${match.url}/:id`} render={RouteRender([...path, match.params.id || ""], [])} />
    </React.Fragment>
  )
}

export const PageRenderer = ({ match, tree, children, ...props }: Props) => (
  <React.Fragment>
    <ScreensaverTrigger>
      <Route path={`${match.url}/:id`} render={RouteRender([], tree)} />
      {children}
    </ScreensaverTrigger>
  </React.Fragment>
)

export default PageRenderer
