// @flow

export const genRandomKey = () => Math.random().toString(36).substring(7)

export const lockedDown = (node: any) => {
  return node.type.startsWith('internal#') || node.id.startsWith('internal#')
}

export default { 
  genRandomKey,
  lockedDown
}