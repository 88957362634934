// @flow


import type { Language } from '../types'


const langs = {
  'de': require(`../translations/de.json`),
  'en': require(`../translations/en.json`),
}

export const setupTranslation = (lang: Language) => {
  const db = langs[lang]
  return (text: string) => {
    if (db[text]) {
      return db[text]
    } else {
      return text
    }
  }
}