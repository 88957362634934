// @flow
import { SET_TREE } from '../actions/tree'
import type { Action } from './types'
import type { Tree } from '../types'

export default function (state: Tree = [], action: Action) {
  switch (action.type) {
    case SET_TREE:
      return action.tree
    default:
      return state
  }
}
