import React, { useState } from "react"

const iframeContent = (center, lang) => `<html>
<head><script src="https://datahub.mister-digigast.at/app.bundle.js" defer></script>
<style>
  ux-dialog-container.active {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  
    ${center === true ? `
      main {
        width:100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
      md-appointment-widget {
        width: 100%;
        height: auto;
      }
    ` : `
      a {
        pointer-events: none !important;
      }
        .widget-feed {
        padding: 0 !important;
        }
        .swiper {
        margin-right: 0 !important;
    }
      md-appointment-widget {
        height: 700px;
      }
      .button, .swiper-button-next, .swiper-button-prev {
        display: none !important;
      }
    `}

</style>
</head><body><main>
  <md-appointment-widget
                  data-hash="667c26ba4aeec4.95475"
                  data-lang="${lang}"
                  data-hotel-slug="hotel-schwaigerhof"
                  data-title-color="#111"
                  data-date-color=""
                  data-info-btn-bgcolor="#746d69"
                  data-info-btn-textcolor="#ffffff"
                /></main>
</body></html>`

export function DigiGastWidget({ lang }) {
  const [open, setOpen] = useState(false)

  return (
    <>
      <div className="main-banner">
        <div style={{ position: 'relative', width: '100%', marginTop: '2rem' }}>
          <div style={{  display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div style={{ width: '100%' }}>
                <iframe title="widget-banner" srcdoc={iframeContent(false, lang)} style={{ width: '100%', height: 700, border: 'none' }} />
              </div>
            <div className="uibtn" style={{ fontSize: '1.5rem', width: 400, height: 80, cursor: 'pointer', marginTop: '2rem' }} onClick={() => setOpen(true)}>
              {lang === 'de' ? 'Übersicht Wochenprogramm' : 'Weekly program overview'}
            </div>
            </div>
        </div>
      </div>
      {<div id={'full-programm'} className={open ? 'active' : undefined}>
        <iframe title="widget-banner" srcdoc={iframeContent(true, lang)} style={{ width: '100%', height: '100vh', border: 'none' }} />
        <div className="uibtn" id="programm-close-button" onClick={() => setOpen(false)}>X</div>
        </div>}
      </>
  )
}
