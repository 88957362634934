// @flow

import React, { Component } from 'react'
import type {
  DropResult
} from 'react-beautiful-dnd'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import type { Image } from '../types'
import styles from './Admin.module.css'


// a little function to help us with reordering the result
const reorder = (list: Image[], startIndex: number, endIndex: number): Image[] => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const grid = 8

const getItemStyle = (item: Image, isDragging: boolean, draggableStyle: any) => console.warn(draggableStyle) || ({
  display: 'flex',
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  margin: grid,
  width: '128px',
  minWidth: '128px',
  height: '72px',
  minHeight: '72px',

  backgroundImage: `url('${item}')`,
  backgroundPosition: `center`,
  backgroundSize: `contain`,
  backgroundRepeat: `no-repeat`,
  border: isDragging ? `1px solid blue` : `1px solid rgba(0,0,0,0.3)`,
  // styles we need to apply on draggables
  ...draggableStyle,
})

const getListStyle = (isDraggingOver: boolean) => ({
  display: 'flex', flexDirection: 'row',
  flexGrow: 1,
  alignItems: 'start',
  background: isDraggingOver ? 'lightblue' : '',
  padding: grid,
  width: '90%',
  height: '120px',
  overflow: 'scroll',
})

type Props = {
  nodeId: string,
  elements: Image[],
  setImages: Image[] => void,
}

export default class AdminSlideList extends Component<Props> {

  onDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    const items = reorder(
      this.props.elements,
      result.source.index,
      result.destination.index,
    )

    this.props.setImages(items)
  }

  delete = (id: string) => () => {
    const { elements } = this.props
    return this.props.setImages(elements.filter((val) => val !== id))
  }

  render() {
    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId="droppable" direction="horizontal">
          {(droppableProvided, droppableSnapshot) => (
            <div
              ref={droppableProvided.innerRef}
              style={getListStyle(
                droppableSnapshot.isDraggingOver,
              )}
            >
              {this.props.elements.map((item, index) => (
                <Draggable key={item} draggableId={item} index={index}>
                  {(draggableProvided, draggableSnapshot) => (
                    <div
                      ref={(ref) => draggableProvided.innerRef(ref)}
                      {...draggableProvided.draggableProps}
                      {...draggableProvided.dragHandleProps}
                      style={getItemStyle(
                        item,
                        draggableSnapshot.isDragging,
                        draggableProvided.draggableProps.style,
                      )}
                    >
                      <button className={styles.slideDelete} type="button" onClick={this.delete(item)}>
                        X
                      </button>
                    </div>
                  )}
                </Draggable>
              ))} 
              {droppableProvided.placeholder}
            </div>
          )}
          
        </Droppable>
      </DragDropContext>
    )
  }
}